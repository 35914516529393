/**
 * src/js/main.js
 *
 * Main javascript file
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */


/**
	* Import modules, modules are stored in `src/js/modules/`
 */
import LazyLoading from "/dist/js/modules/lazyload.js";
import LogoAnimation from "/dist/js/modules/logo.js";
import Dropdown from "/dist/js/modules/dropdown.js";
import News from "/dist/js/modules/news.js";
import Eyecatcher from "/dist/js/modules/eyecatcher.js";
// import SnapSections from "/dist/js/modules/snap_sections.js";
// Ideas for further modules
// - Slider / Carousel
// - Lightbox
// - Overlay


/**
 * Application main class
 */
class App {

	/**
	 * Constructor
	 */
	constructor() {

		// Class properties, can only be declared inside a method … :-(
		this.debug = false;
		this.logo = document.querySelector('.main-header__top');

		document.addEventListener('DOMContentLoaded', () => this.init());
		window.onload = () => this.pageInit();

		
		let brand = document.querySelector('.brand');
		brand.addEventListener('click', function() {
			hideMailgo();
		});

	}



	/**
	 * init
	 * Called on DOMContentLoaded, so a good place to setup things not dependend
	 * on the page finished rendering
	 */
	init() {
		if (this.debug) {
			console.debug('APP::init');
		}

		document.body.classList.add('page-has-loaded');

		window.addEventListener('resize', () => this.throttle(this.resizeHandler));
		window.addEventListener('scroll', () => this.throttle(this.scrollHandler));

		// "Safari Bug", trigger repaint or something like that ;-)
		window.setTimeout(() => {
			this.logo.style.setProperty('--yoffset', '1px');
			this.logo.style.setProperty('--yoffset', '0px');
			this.logo.style.display = 'none';
			this.logo.style.display = 'flex';
		}, 100);
	}



	/**
	 * pageInit
	 * Called on window.load, i.e. when the page and all assets have been
	 * loaded completely and the page has rendered
	 *
	 * @return void
	*/
	pageInit() {

		if (this.debug) {
			console.debug('APP::pageInit');
		}

		document.body.classList.add('page-has-rendered');

		// Let's see if we have a header element and get it's height (for
		// detecting scroll past header, see `App.scrollHandler`
		this.header = document.querySelector('header');
		if (this.header) {
			let rect = this.header.getBoundingClientRect();
			this.headerBottom = rect.top + rect.height;
		}

		this.main();
	}


	/**
	 * Main method
	 * Put main business logic here
	 *
	 * @return void
	 */
	main() {
		// console.info("It works!");

		let lazyLoading = new LazyLoading();
		lazyLoading.init();

		new Dropdown();
		new LogoAnimation();
		new Eyecatcher();
		// this.SnapSections = new SnapSections();
		new News();
	}


	/*
	 * Debounced / throttled scroll handler. Put your scroll-related stuff here!
	 * @return void
	 */
	scrollHandler() {
		let y = window.scrollY;

		if (this.debug) {
			console.debug(`Scroll position: ${y}`);
		}

		// Set classes on body depending on how far the page has scrolled
		document.body.classList.toggle('has-scrolled', y > 0);
		document.body.classList.toggle('has-scrolled-a-bit', y > 160);
		document.body.classList.toggle('has-scrolled-past-header', y > this.headerBottom);
		document.body.classList.toggle('has-scrolled-100vh', y > window.innerHeight);

		// Todo: Scroll direction!

		// Extend here …

		const maxy = 154;
		this.logo.style.setProperty('--yoffset', -1 * Math.min(y, maxy) + 'px');
	}


	/**
	 * Debounced / throttled scroll handler. Put your resize-related stuff here!
	 *
	 * @return void
	 */
	resizeHandler() {
		let width = window.innerWidth,
			height = window.innerHeight;

		if (this.debug) {
			console.debug(`Window has been resized to ${width}, ${height}`);
		}

		// Set custom properties
		document.body.style.setProperty('--window-width', `${width}px`);
		document.body.style.setProperty('--window-height', `${height}px`);

		// Extend here …

		// Re-calculate the scroll-margin-top values for the sections linked to
		// by the main navigation
		// this.SnapSections.run();
	}


	/**
	 * Throttler method
	 *
	 * @param callable: Handler to be called on throttled scroll event
	 * @return void
	 */
	throttle(handler) {

		this.ticking = false;

		if (!this.ticking) {
			window.requestAnimationFrame(() => {
				handler.call(this);
				this.ticking = false;
			});
			this.ticking = true;
		}
	}


	/**
	 * execute if click/interaktion is outside of the Selector
	 *
	 * @param selector: Element selector (e.g. class, id) to watch for
	 * @param callback: function to execute if interaktion is outside of the Selector
	 *
	 * Example:
	 * this.app.onOutsideClick('#main-menu', this.closeMenu);
	 */
	onOutsideClick(selector, callback) {
			document.addEventListener('click', (event) => {
				if (event.target.parentNode.closest(selector) == null) {
					callback();
				}
			});
		};
};

new App();
